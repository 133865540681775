.container{
    display: flex;
    flex-direction: column;
    font-family: 'Montserrat', sans-serif;
    color: #63748D;
    height: 100%;
}
.category{
    margin: 0 5vw;
    margin-top: 3vh;
}
.contact_container{
    margin-top: 3vh;
    display: flex;
    height: 100%;
    /* justify-content: space-between; */
}
.address{
    display: flex;
    flex-direction: column;
    margin-left: 5vw;
}
#up{
    font-size: 3.5vh;
    font-weight: bold;
}
.phone_info{
    display: flex;
    flex-direction: column;
    margin-left: 2.5vw;
}
.phones{
    display: flex;
    /* flex-direction: column; */
    font-size: 2.8vh;
    margin-bottom: 0.75vh;
}
#snd_num{
    margin-left: 1.5vw;
}
.map{
    margin-top: 20px;
    width: 100%;
    min-height: 100%;
    display: flex;
}
.map iframe{
    width: 100%;
    height: 100%;
}


@media (min-width: 768px) and (max-width: 1024px) {
    #up{
        font-size: 2.7vh;
    }
    .phones{
        font-size: 2.1vh;
    }
}

@media (max-width: 768px) {
    #up{
        font-size: 2.4vh;
    }
    .phones{
        font-size: 1.8vh;
    }
}

@media (max-width: 426px) {
    .container{
        min-height: 100vh;
    }
    .contact_container{
        display: flex;
        flex-direction: column;
    }
    .phone_info{
        margin-top: 20px;
        margin-left: 5vw;
    }
    .category{
        width: 80%;
        height: 40px;
    }
    .phones{
        font-size: 2vh;
        font-weight: bold;
    }
    #snd_num{
        margin-left: 20px;
    }
    .btn{
        margin: 0;
    }
    .map{
        display: flex;
        flex-direction: column;
        height: 70vh;
    }
    .map iframe{
        height: inherit !important;
    }
}

@media (max-width: 426px) {
    .container{
        min-height: 100vh;
    }
    .contact_container{
        display: flex;
        flex-direction: column;
    }
    .phone_info{
        margin-top: 20px;
        margin-left: 5vw;
    }
    .category{
        width: 80%;
        height: 40px;
    }
    .phones{
        font-size: 1.3vh;
        font-weight: bold;
    }
    #snd_num{
        margin-left: 20px;
    }
}