.container{
    color: #46B8D4;
    margin: 10px;
    padding: 0;
    display: flex;
    flex-direction: column;
    width: calc(1/3*100% - 20px);
    border: 2px solid #46B8D4;
    box-shadow: 0px 0 10px rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}
.container:hover{
    border-color: #09e741;
    box-shadow: 0px 0 10px rgba(9, 231, 65, 0.5);
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}

.image_container {
    height: 50%;
    display: flex;
}
.image_container img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.title{
    color: black;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    font-weight: bold;
}

.characteristics_container{
    display: flex;
    width: 70%;
    justify-content: space-between;
    margin-top: 3%;
}
.left_side_characteristic{
    display: flex;
    flex-direction: column;
}
.right_side_characteristic{
    display: flex;
    flex-direction: column;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
}
.characteristic{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 1em;
}

.characteristic_value{
    color: black;
    /* font-weight: bold; */
}

.price_container{
    width: 98%;
    margin-top: auto;
    margin-bottom: 5px;
    border-top: 2px solid #46B8D4;
    /* flex-direction: column;
    flex-wrap: wrap; */
}
.price_container tr{
    text-align: center;
}
.price_border{
    background-color: #005DBB;
    color: #fff;
}
.price_border td:first-child{
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}
.price_border td:last-child{
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

@media (min-width: 701px) and (max-width: 1024px) {
    .container{
        margin: 1vh 2vw;
        width: calc(1/2*100% - 4vw);
    }
}

@media (min-width: 466px) and (max-width: 700px) {
    .container{
        margin: 1vh 7vw;
        width: calc(100% - 14vw);
    }
    .characteristic{
        margin-top: 20px;
        font-size: 20px;
    }
    .title{
        font-size: 25px;
    }
    .price{
        font-size: 20px;
    }
}

@media (max-width: 466px) {
    .container{
        margin: 1vh 3vw;
        width: calc(100% - 6vw);
    }
    .characteristic{
        margin-top: 15px;
        font-size: 16px;
    }
    .title{
        font-size: 20px;
    }
    .price{
        font-size: 16px;
    }
}

@media (max-width: 340px) {
    .characteristic{
        margin-top: 10px;
        font-size: 14px;
    }
    .title{
        font-size: 20px;
    }
    .price{
        font-size: 14px;
    }
}