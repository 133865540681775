.container{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 10px 0;
    width: 100%;
    background-color: #F6F3ED;
    border-bottom: 1px solid black;
}

.img_container{
    margin: 0 10%;
    display: flex;
    width: 13vw;
}

img{
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.nav{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 10%;
    text-transform: uppercase;
    font-family: 'Open-Sans', sans-serif;
    font-weight: bold;
    font-size: 18px;
    /* color: #005FAD; */
}

.nav a{
    margin: 0 10px;
    cursor: pointer;
    background-size: 200% 100%;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}
.nav a:hover{
    background-position: 100% 0;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}
.nav a:last-child{
    margin-right: 0;
}

.gradient_text{
    display: inline;
    background: -webkit-linear-gradient(45deg, #1255A0, #008BD1, #00A229);
    background: linear-gradient(45deg, #1255A0, #008BD1, #00A229);
    background-clip: text;
    -webkit-background-clip: text;
    text-fill-color: transparent;
    -webkit-text-fill-color: transparent;
    /* color: transparent; */
}
@supports not (background-clip: text) {
    .gradient_text{
        background: none;
        background-clip: border-box;
        -webkit-background-clip: border-box;
        -webkit-text-fill-color: initial;
        color: #1255A0;
    }
    .gradient_text a{
        color: #1255A0;
    }
 }
@supports not (-webkit-text-fill-color: transparent) {
    .gradient_text{
        background: none;
        background-clip: border-box;
        -webkit-background-clip: border-box;
        -webkit-text-fill-color: initial;
        color: #1255A0;
    }
    .gradient_text a{
        color: #1255A0;
    }
 }
 @supports not (-webkit-background-clip: text) {
    .gradient_text{
        background: none;
        background-clip: border-box;
        -webkit-background-clip: border-box;
        -webkit-text-fill-color: initial;
        color: #1255A0;
    }
    .gradient_text a{
        color: #1255A0;
    }
 }

@media (min-width: 768px) and (max-width: 1024px){
    .container{
        justify-content: center;
    }
    .img_container{
        display: none;
    }
    .nav{
        justify-content: center;
        align-items: center;
        margin-right: 0;
    }
}


/* burger */
.burger{
    height: 50px;
    width: 50px;
    position: fixed;
    top: 22px;
    right: 0;
    z-index: 1050;
    display: flex;
    align-items: center;
    justify-content: center;
}

.burger_btn{
    display: none;
    width: 20px;
    height: 20px;
    position: relative;
    cursor: pointer;
}

.burger_btn:before{
    content: "";
    position: absolute;
    top: 1px;
    width: 20px;
    background-color: #005DBB;
    height: 3px;
    border-radius: 2px;
    transition: all 0.4s;
}

.burger_btn span {
    content: "";
    position: absolute;
    top: 9px;
    width: 20px;
    background-color: #005DBB;
    height: 3px;
    border-radius: 2px;
    opacity: 1;
    transition: all 0.4s;
}

.burger_btn:after{
    content: "";
    position: absolute;
    bottom: 0;
    width: 20px;
    background-color: #005DBB;
    height: 3px;
    border-radius: 2px;
    transition: all 0.4s;
}

.burger_active:before{
    transform: rotate(45deg);
    top: 42%;
    transition: all 0.4s;
}
.burger_active span{
    opacity: 0;
    transition: all 0.4s;
}
.burger_active:after{
    transform: rotate(-45deg);
    bottom: 42%;
    transition: all 0.4s;
}

.menu{
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1000;
    transform: translateX(-130%);
    transition: all 0.5s;
}

.menu.active{
    transform: translateX(0);
}

.blur{
    width: 100vw;
    height: 100vh;
    left: 30%;
    backdrop-filter: blur(2px);
    position: absolute;
}

.menu_content{
    width: 30%;
    height: 100%;
    background-color: #F6F3ED;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 0.4s;
}

.menu_header{
    font-size: 2rem;
    padding-top: 10px;
    text-align: center;
    width: 100%;
    background-size: 200% 100%;
    text-transform: uppercase;
    font-family: 'Open-Sans', sans-serif;
    font-weight: bold;
}

.menu_content ul li a {
    text-decoration: none;
    font-size: 1.3rem;
}

.menu_content ul {
    width: 100%;
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 24px;
    z-index: 2000;
}

.menu_content ul li{
    list-style-type: none;
    text-decoration: none;
    outline: none;
    display: inline;
    margin: 10px;
    background-size: 200% 100%;
    text-transform: uppercase;
    font-family: 'Open-Sans', sans-serif;
    font-weight: bold;
    font-size: 18px;
}

@media screen and (max-width: 768px){
    .burger_btn{
        display: block;
    }
    .container{
        display: none;
    }
    .menu_content{
        width: 100%;
    }
    .blur{
        backdrop-filter: none;
    }
}