.container{
    margin: 0 1.3vw;
    margin-top: 20px;
    font-size: 14px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    color: #2A2D2E;
    font-weight: bold;
}
.condition{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: calc(1/2*100% - 20px);
    margin-top: 15px;
}

.condition img{
    width: 30px;
    height: 30px;
    object-fit: cover;
}
.condition span{
    margin-left: 3px;
}

.category{
    display: flex;
    flex-direction: column;
}

.title{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 0.8vw;
}

@media (max-width: 768px) {
    .container{
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
    }
    .condition{
        width: 90%;
        font-size: 1.7vh;
    }
    .condition:nth-child(2){
        margin-top: 39px;
    }
}

@media (max-width: 426px) {
    .title{
        width: 80%;
        height: 40px;
    }
    .condition:last-child{
        margin-bottom: 50px;
    }
}