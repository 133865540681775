.container{
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    width: 80%;
    background-color: #fff;
    box-shadow: 10px 0 4px -4px rgb(0 0 0 / 50%), -10px 0 4px -4px rgb(0 0 0 / 50%);
    min-height: 100vh;
}

.full{
    height: 100vh;
    overflow: hidden;
}

@media (max-width: 1024px) {
    .full{
        min-height: 100vh;
        overflow: visible;
        height: 100%;
    }
    .container{
        width: 100% ;
    }
}