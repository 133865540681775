.category_container{
    display: flex;
    flex-direction: column;
}
.car_container{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    background-color: #fff;
    cursor: pointer;
    /* box-shadow: 10px 0 4px -4px rgb(0 0 0 / 50%), -10px 0 4px -4px rgb(0 0 0 / 50%); */
}
