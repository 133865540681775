.container{
    display: flex;
    flex-direction: column;
    width: 100%;
}

#feedbackForm{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#feedbackForm input{
    width: 100%;
    margin-top: 10px;
    border: none;
    outline: none;
}

.privacy{
    display: flex;
    width: 100%;
    margin-top: 10px;
    align-items: center;
}
.privacy input{
    margin-right: 10px;
}