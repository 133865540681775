.draw {
  display: flex;
  align-items: flex-end;
  background: none;
  border: 0;
  box-sizing: border-box;

  /* box-shadow: inset 0 0 0 2px #63748D; */
  color: #63748D;
  font-size: inherit;
  font-weight: 700;
  position: relative;
  /* vertical-align: middle; */
  transition: color 0.25s;
}
.draw::before,
.draw::after {
    box-sizing: inherit;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
}
.draw::before,
.draw::after {
    border: 2px solid transparent;
    width: 0;
    height: 0;
}

.draw::before {
    top: 0;
    left: 0;
}

.draw::after {
    bottom: 0;
    right: 0;
  }

.draw:hover {
    color: #00eb3b;
}

.draw:hover::before,
.draw:hover::after {
    width: 100%;
    height: 100%;
}

.draw:hover::before {
    border-top-color: #00eb3b;
    border-right-color: #00eb3b;
    transition: width 0.25s ease-out,
      height 0.25s ease-out 0.25s;
}

.draw:hover::after {
    border-bottom-color: #00eb3b;
    border-left-color: #00eb3b;
    transition: border-color 0s ease-out 0.5s,
      width 0.25s ease-out 0.5s,
      height 0.25s ease-out 0.75s;
}

.send_btn{
    border: 0;
    font-family: 'Montserrat', sans-serif;
}


.f_container{
    position: fixed;
    bottom: 50px;
    right: 50px;
    width: 30px;
    height: 30px;
    text-align: center;
    display: flex;
    overflow: hidden;
    cursor: pointer;
}

.obj{
    display: block;
    margin: auto;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.obj {
    fill: #005DBB;
    animation: rotate 1.2s linear infinite;
}

@keyframes rotate {
    from {
        transform: rotate(3deg);
    }
    50%{
        transform: rotate(-6deg);
    }
    100%{
        transform: rotate(3deg);
    }
  }

  @media screen and (max-width: 768px){
    .f_container{
        right: 12px;
    }
}