.container{
    margin: 0 1.3vw;
    margin-top: 20px;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    font-family: 'Montserrat', sans-serif;
    color: #2A2D2E;
    font-weight: bold;
}
.condition{
    display: flex;
    align-items: center;
    margin-top: 15px;
}

.condition img{
    min-width: 30px;
    width: 30px;
    height: 30px;
    object-fit: cover;
}
.condition span{
    margin-left: 10px;
}

.category{
    display: flex;
    flex-direction: column;
}

.title{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 0.8vw;
}

.subtitle{
    margin-top: 30px;
}

.subtitle h3{
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    color: #63748D;
    font-size: 1.3em;
}

.condition:last-child{
    margin-bottom: 20px;
}

@media (max-width: 768px) {
    .container{
        font-size: 1.8vh;
    }
}

@media (max-width: 426px) {
    .title{
        width: 80%;
        height: 40px;
    }
}