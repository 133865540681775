.container{
    margin: 10px 1.3vw;
    position: relative;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
}
.container::before{
    height: 100%;
    content: "";
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    background: linear-gradient(45deg, #0056A6, #008BD1, #0056A6);
    transform: skew(-15deg);
    width: calc(1/3*100% - 20px);
    border-radius: 3px;
}

.title{
    margin: 10px 10px;
    font-family: 'Montserrat', sans-serif;
    font-size: 25px;
    font-weight: bold;
    color: #fff;
    z-index: 1;
}

.margin_class{
    margin: 0;
}

@media (min-width: 700px) and (max-width: 1024px) {
    .container{
        margin: 10px 3.2vw;
    }
    .margin_class{
        margin: 0;
    }
}

@media (max-width: 700px) {
    .container{
        margin: 10px 7.5vw;
    }
    .container::before{
        width: 100%;
    }
    .margin_class{
        margin: 0;
    }
}