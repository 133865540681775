html{
    margin: 0;
    padding: 0;
}

body{
    background-color: #EDE7E6;
    text-decoration: none;
}

a{
    text-decoration: none;
}