.container{
    position: relative;
    color: #848484;
    max-height: 100vh;
    min-height: 100%;
}
.up{
    position: absolute;
    top: 10%;
    left: 15%;
    font-size: 3vw;
    text-transform: uppercase;
}
.down{
    position: absolute;
    top: 20%;
    right: 15%;
    font-size: 3vw;
    text-transform: uppercase;
}